import * as React from 'react';
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import Link from "../components/Link/Link";
import { BuilderComponent } from "@builder.io/react";
import {useLocation} from "@reach/router";

const defaultTitle = 'Project Nightfall Philanthropy | Highlights';
const faviconUrl = 'https://files.projectnightfall.org/assets/others/pno_favicon_16px.png';

function BlogHighlightTemplate({data, pageContext}) {
    const models = data?.allBuilderModels;
    const content = models.page[0]?.content;
    const {blogPost} = pageContext;

    return(
        <>
            <Helmet>
                <title>{blogPost?.data.title || defaultTitle}</title>
                <link rel="icon" type="image/x-icon" href={faviconUrl}></link>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0;" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:url" content={useLocation().href} />
                <meta property="og:title" content={blogPost?.data.title} />
                <meta property="og:description" content={blogPost?.data.description} />
                <meta property="og:image" content={blogPost?.data.coverPhoto} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Project Nightfall Philanthropy" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={blogPost?.data.title} />
                <meta name="twitter:image" content={blogPost?.data.coverPhoto} />
            </Helmet>
            <BuilderComponent
                renderLink={Link}
                name="page"
                content={content}
            />
        </>
    );
}

export default BlogHighlightTemplate;

export const pageQuery = graphql`
  query {
    allBuilderModels {
      page(
        target: { urlPath: "/highlight" }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
